<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="500px"
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="申请编号">
                <el-input v-model="form.code" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="岗位名称">
                <el-input v-model="form.positionName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="申请人">
                <el-input v-model="form.customerName" autocomplete="off" disabled></el-input>
            </el-form-item>

            <el-form-item label="审核状态" prop="isPass">
                <el-select v-model="form.isPass" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in verifyStated"
                               :key="item.value"
                               :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="面试地点" v-if="operation==='invite'">
                <el-input v-model="form.inviteAddress" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="面试时间" v-if="operation==='invite'">
                <el-date-picker v-model="form.inviteTime"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>



            <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    var enumData = require('@/config/data');
    import { InviteVerify, EnrollVerify } from '@/api/apply';
    export default {
        name: 'VerifyApply',
        data() {
            return {
                operation: '',
                verifyStated: enumData.verifyStated,
                form: {
                    id: 0,
                    positionName: '',
                    code: '',
                    customerName: '',
                    inviteAddress: '',
                    inviteTime: null,
                    isPass: null,
                    note: ''
                },
                rules: {
                    isPass: [{ required: true, trigger: 'blur', message: '请选择审核状态' }],
                    note: [{ required: true, trigger: 'blur', message: '请输入审核备注' }],
                },
                title: '',
                dialogFormVisible: false,
            }
        },
        methods: {
            showEdit(row, operation) {
                this.title = '审核';
                this.operation = operation;

                this.form.id = row.id
                this.form.positionName = row.positionName;
                this.form.code = row.code;
                this.form.customerName = row.customerName;
                this.form.inviteAddress = row.position.address + `--${row.position.company}`;

               
                this.dialogFormVisible = true;
            },
            close() {
                this.$refs['form'].resetFields()
                this.form = this.$options.data().form
                this.dialogFormVisible = false
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        this.$baseMessage("编号无效", 'error');
                    }
                    if (this.operation == 'invite') {
                        if (this.form.isPass == 1) {
                            if (!this.form.inviteTime) return this.$baseMessage('请选择面试时间', 'error');
                            if (this.form.inviteAddress === '') return this.$baseMessage('请选择面试地点', 'error');
                        }
                        const { isSucceed, message } = await InviteVerify(this.form);
                        if (!isSucceed) return this.$baseMessage(message, 'error');
                        if (isSucceed) this.close();
                    }
                    else if (this.operation == 'enroll') {
                        const { isSucceed, message } = await EnrollVerify(this.form);
                        if (!isSucceed) return this.$baseMessage(message, 'error');
                        if (isSucceed) this.close();
                    }

                    this.$emit('fetch-data');
                })
            },
        },
    }
</script>
