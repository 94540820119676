<template>
    <div class="userManagement-container">
        <vab-query-form>
            <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                <el-form-item>
                    <el-input v-model.trim="queryForm.PositionName"
                              placeholder="请输入岗位名称"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-select v-model="queryForm.stated" placeholder="请选择">
                        <el-option v-for="item in applyStated"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.customerName"
                              placeholder="请输入用户名称"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.customerMobile"
                              placeholder="请输入用户手机"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" @click="queryData">
                        查询
                    </el-button>
                </el-form-item>
            </el-form>

        </vab-query-form>

        <el-table v-loading="listLoading"
                  :data="list"
                  border
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">


            <el-table-column show-overflow-tooltip label="操作" width="220">
                <template #default="{ row }">
                    <el-button round type="info" @click="resume(row)">查看简历</el-button>
                    <el-button round type="primary" @click="invite(row)" v-if="row.stated===0">面试审核</el-button>
                    <el-button round type="warning" @click="enroll(row)" v-if="row.stated===2">录取审核</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             label="ID"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="200px"
                             prop="code"
                             label="申请编号"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="positionName"
                             label="岗位名称"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="positionSalary"
                             label="岗位工资">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="customerNickName"
                             label="用户昵称">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="customerName"
                             label="用户名称">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="customerMobile"
                             label="用户手机">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="stated"
                             label="状态">
                <template #default="{ row }">
                    <el-tag type="danger" v-if="row.stated===1 || row.stated===4">
                        {{ applyStated.find(d=>d.value==row.stated).name }}
                    </el-tag>
                    <el-tag type="success" v-else>{{ applyStated.find(d=>d.value==row.stated).name }}</el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="200px"
                             prop="note"
                             label="备注"></el-table-column>


            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="creationTime"
                             label="申请时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="commision"
                             label="佣金"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="parentNickName"
                             label="推荐者名称">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="parentMobile"
                             label="推荐者手机">
            </el-table-column>
        </el-table>

        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>

        <edit ref="edit" @fetch-data="fetchData"></edit>
        <resume ref="resume" @fetch-data="fetchData"></resume>
    </div>
</template>

<script> 
    import { GetApplies } from '@/api/apply';
    import Edit from './components/verify';
    import Resume from './components/Resume';

    var enumData = require('@/config/data');
    export default {
        name: 'ApplyList',
        components: { Edit, Resume},
        data() {
            return {
                applyStated: enumData.applyStated,
                list: null,
                listLoading: false,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    customerName: '',
                    customerMobile: '',
                    PositionName: '',
                    stated: -100
                },
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            resume(row) {
                this.$refs['resume'].showEdit(row);
            },
            invite(row) {
                this.$refs['edit'].showEdit(row, 'invite');
            },
            enroll(row) {
                this.$refs['edit'].showEdit(row,'enroll');
            },
            setSelectRows(val) {
                this.selectRows = val
            },
            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                const { isSucceed, message, result, total } = await GetApplies(this.queryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                console.log(result)
                this.list = result;
                this.total = total;
            },
        },
    }
</script>

