<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="70%"
               @close="close">
        <div class="productpage">
            <div class="title">
                <el-divider content-position="center">基本信息</el-divider>
                <el-form ref="form" label-width="100px">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="姓名：">
                                <label>{{form.customerName}}</label>
                            </el-form-item>
                        </el-col>
                        <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="性别：">
                                <label>{{form.sex==1?'男':'女'}}</label>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="手机：">
                                <label>{{form.mobile}}</label>
                            </el-form-item>
                        </el-col>
                        <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="年龄：">
                                <label>{{form.age}}</label>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="邮箱：">
                                <label>{{form.email}}</label>
                            </el-form-item>
                        </el-col>
                        <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="期望岗位：">
                                <label>{{form.hopePosition}}</label>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="期望薪资：">
                                <label>{{form.hopeWages}}</label>
                            </el-form-item>
                        </el-col>
                        <el-col ::xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="期望工作地：">
                                <label>{{form.hopeCity}}</label>
                            </el-form-item>
                        </el-col>
                    </el-row>



                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="12">
                            <el-form-item label="简历头像：">
                                <img class="img" :src="form.image" />
                            </el-form-item>
                        </el-col> 
                    </el-row>
                </el-form>
            </div>

            <div class="title">
                <el-divider content-position="center">技能证书</el-divider>
                <p>
                    <el-tag v-for="(item,index) in form.certificates" :key="index">{{item.name}}</el-tag>
                </p>
            </div>

            <div class="title">
                <el-divider content-position="center">教育履历</el-divider>
            </div>
            <div class='experience'>
                <el-timeline>
                    <el-timeline-item v-for="(item,index) in form.educations" placement="top" :key="index">
                        <el-card>
                            <h5>学校名称：{{item.name}}</h5>
                            <p>入学年份：{{item.enrollYear}}</p>
                            <p>专业：{{item.major}}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>


            <div class="title">
                <el-divider content-position="center">工作经历</el-divider>
            </div>
            <div class='experience'>
                <el-timeline>
                    <el-timeline-item v-for="(item,index) in form.companies" placement="top" :key="index">
                        <el-card>
                            <h5>公司名称：{{item.name}}</h5>
                            <p>时间：{{item.startTime}}~{{item.endTime}}</p>
                            <p>
                                描述： {{item.desc}}
                            </p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>


            <div class="title">
                <el-divider content-position="center">项目经验</el-divider>
            </div>
            <div class='experience'>
                <el-timeline>
                    <el-timeline-item v-for="(item,index) in form.projects" placement="top" :key="index">
                        <el-card>
                            <h5>项目名称：{{item.name}}</h5>
                            <p>时间：{{item.startTime}}~{{item.endTime}}</p>
                            <p>
                                描述： {{item.desc}}
                            </p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import { GetResume } from '@/api/resume';
    export default {
        name: 'Resume',
        data() {
            return {
                title: '简历',
                dialogFormVisible: false,
                customerId: 14,
                form: {
                    "id": 11,
                    "image":'',
                    "name": "",
                    "mobile": "",
                    "email": "",
                    "sex": 1,
                    "age": 33,
                    "hopeWages": "",
                    "hopePosition": "",
                    "hopeCity": "",
                    "certificates": [],
                    "companies": [],
                    "projects": [],
                    "educations": []
                }
            }
        },
        methods: {
            showEdit(row) {
                this.customerId = row.customerId;
                this.dialogFormVisible = true;
                this.getResume();
            },
            async getResume() {
                const { isSucceed, message, result } = await GetResume(this.customerId);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                console.log('[getResume]' + JSON.stringify(result));
                this.form = Object.assign({}, result);
            },
            close() {
                this.dialogFormVisible = false
            },
        },
    }
</script>


<style scoped>
    .img {
        width: 100%;
        max-width: 150px;
    }

    .productpage {
        /*padding: 15px 100px 0px 100px;*/
    }

        .productpage .project {
            display: flex;
            justify-content: space-around;
        }

    .ifns {
        width: 85%;
        display: inline-block;
        outline: 1px dashed rgba(84, 92, 100, 0.1);
    }

    .experience {
        text-align: left;
    }

    .content {
        text-align: center;
        display: inline;
        position: relative;
        margin: 20px;
    }

        .content p {
            width: 95%;
            color: #fff;
            font-size: 14px;
            text-align: center;
        }

    .hover {
        position: absolute;
        bottom: 5px;
        left: 7.5%;
        background-color: rgba(84, 92, 100, 0.3);
        height: 60px;
        width: 85%;
        line-height: 60px;
        cursor: pointer;
    }

        .hover:hover {
            background-color: rgba(84, 92, 100, 0.6);
        }

    h2 {
        border: 1px solid #ccc;
        height: 0px;
    }

    .title p {
        color: #8c8888;
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

        .title p .el-tag {
            margin: 0px 5px;
            cursor: pointer;
        }

    .info {
        font-size: 14px;
        color: #72767b;
        line-height: 25px;
    }
</style>