import request from '@/network/request';


/**
 * ��ѯ��λ�����б�
 */
export async function GetApplies(data) {
    return request.post('/Apply/GetApplies',data);
}


/**
 * �����������
{
  "id": 0,
  "isPass": 0,
  "note": "string"
}
 */
export async function InviteVerify(data) {
    return request.post('/Apply/InviteVerify', data);
}


/**
 * ¼ȡ���
{
  "id": 0,
  "isPass": 0,
  "note": "string"
}
 */
export async function EnrollVerify(data) {
    return request.post('/Apply/EnrollVerify', data);
}
